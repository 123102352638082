import React,{useEffect} from 'react'
import ContactForm from './ContactForm';

const ContactUs = () => {
    useEffect(() => {
        console.log("herer");
    }, []);
    return (

        <section className="web_content">
            <div className="">
                <div className="content_section_scroll">
                    <div className="container-fluid">
                        <div className="row d-flex align-items-top">
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                                <div className="common_content">
                                    <h1>Have A Question? Ask Us!</h1>
                                    <ContactForm />
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                <div className="contact_infos">
                                    <h3>Contacts</h3>
                                    <div className="contact_info">
                                        <i className="fa fa-map-marker"></i>
                                        <span>3155 Half Dome Drive, Pleasanton, CA<br /> 94566</span>
                                    </div>
                                    <div className="contact_info">
                                        <a href="mailto:sales@spmsale.com">
                                            <i className="fa fa-envelope"></i>
                                            <span>sales@spmsale.com</span>
                                        </a>
                                    </div>
                                    <div className="contact_info">
                                        <a href="tel:+19253971610">
                                            <i className="fa fa-phone"></i>
                                            <span>+1 (925) 397-1610</span>
                                        </a>
                                    </div>
                                    <div className="contact_info">
                                        <a href="www.spmsale.com">
                                            <i className="fa fa-globe"></i>
                                            <span>www.spmsale.com</span>
                                        </a>
                                    </div>
                                </div>
                                <div className="info_tab">
                                    <h4>Pacific Time Zone</h4>
                                    <span>Monday-Friday : 8am to 5pm <br />
                                        Saturday-Sunday : Closed</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div className="info_tab">
                                    <h4>Over 200 Manufacturers</h4>
                                    <p>SPM Solution represents more than 200 Manufacturers, we are confident that a solution can be provided to service your electronic needs.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default ContactUs
