import React from 'react'

const Footer = () => {
    return (

        <section className="web_footer wf_static">
            <div className="footer_left">
                <ul className="navbar-nav">
                    <li className="nav-item"><a href="#">Terms &amp; conditions</a></li>
                    <li className="nav-item"><a href="#">Privacy policy</a></li>
                    <li className="nav-item"><a href="#">Condition of Sales</a></li>
                    <li className="nav-item"><a href="#">Condition of Purchase</a></li>
                    <li className="nav-item"><a href="#">Terms of Use</a></li>
                </ul>
            </div>
            <div className="footer_right">
                <ul>
                    <li><img src="images/nbaa.png" alt="" /></li>
                    <li><img src="images/customer_satisfaction.png" alt="" /></li>
                </ul>
                <p>D &amp; B : 071506903 | CAGE CODE : 7S0N4 <br />
                    &copy; 2021 SPM Sales &amp; Solution . All Rights Reserved</p>
            </div>

        </section>

    )
}

export default Footer
