import React, { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from "react-router-dom";
import $ from 'jquery';

import ForgotPass from './ForgotPass';
import ChangePass from './ChangePass';

const axios = require('axios');

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('')
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    
    const logIn = (e) => {
        e.preventDefault();
        var validation = true;
        $('#emailError').text("");
        $('#passwordError').text("");
        const validEmail = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
        if (!validEmail.test(email)) {
            $('#emailError').text("Email is not valid");
            validation = false;
        }
        const validPass = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@_])[A-Za-z\d@_]{8,}$/;
        if (!validPass.test(password)) {
            $('#passwordError').text("Password is not valid");
            validation = false;
        }
        if (validation == false) {
            return false;
        }
        const data = {
            'email': email,
            'password': password,
        }
        try {
            axios.post('https://api.spmsale.com/api/login', data)
                .then(function (response) {
                    if (response.data.token) {
                        const loginData = JSON.stringify(response.data);
                        localStorage.setItem('loginData', loginData);
                        navigate("/");
                    } else {
                        setError(response.data.error);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        const passwordTokern = searchParams.get("email") ? searchParams.get("email") : '';
        if(passwordTokern) {
            window.$('#ChangePassModel').modal('show');          
        }
    },[]);
    return (

        <section className="web_content">
            <div className="common_bg">
                <div className="content_section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12  offset-xl-3 offset-lg-3 offset-md-3 offset-sm-0">
                                <div className="common_content">
                                    <div className="text-center">
                                        <h3>Welcome to SPM Sales & Solution</h3>
                                        <h3>Log In To Your Account</h3>
                                    </div>
                                    <div className="contact_form">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div className="cf_tab">
                                                    <i className="fa fa-user icon_placeholder"></i>
                                                    <input type="text" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="E-mail" />
                                                </div>
                                                <div id="emailError" className="error" ></div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div className="cf_tab">
                                                    <i className="fa fa-lock icon_placeholder"></i>
                                                    <input type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
                                                </div>
                                                <div id="passwordError" className="error" ></div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 error ">
                                                <div id="Unauthenticated" className="error" >{error}</div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="cf_tab text-center">
                                                    <button type="submit" className="btn btn_inquiry" onClick={logIn}>Log In</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="cf_tab text-center">
                                                    <a className="btn btn_inquiry" data-toggle="modal" data-target="#ForgotPassModel">Forgot Password?</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="ForgotPassModel" tabIndex="-1" role="dialog" aria-labelledby="ForgotPassLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="ForgotPassLabel">Forgot Password</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <ForgotPass/>
                        </div>

                    </div>
                </div>
            </div>
            <div className="modal fade" id="ChangePassModel" tabIndex="-1" role="dialog" aria-labelledby="ChangePassLabel" data-backdrop="static" data-keyboard="false" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="ForgotPassLabel">Change Password</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <ChangePass/>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )
}

export default Login
