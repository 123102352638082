import React, { useEffect, useState } from 'react'
import { NavLink, useParams } from "react-router-dom";
import Pagination from '@material-ui/lab/Pagination';

const axios = require('axios');


const Product = () => {
    const [Product, setProduct] = useState([]);
    const [ProductId, setProductId] = useState();
    const [page, setPage] = useState(1);
    const [Limit, setLimit] = useState(15);
    const [totalPage, setTotalPage] = useState(1);
    const getProduct = (sub_id) => {
        try {
            axios.post('https://api.spmsale.com/api/product', { id: sub_id, page: 1, limit: Limit })
                .then(function (response) {
                    if (response.data.status) {
                        if (response.data.count % Limit) {
                            setTotalPage(parseInt(response.data.count / Limit) + 1);
                        } else {
                            setTotalPage(parseInt(response.data.count / Limit));
                        }
                        setProduct(response.data.data);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    }
    const handleChange = (event, value) => {
        setPage(value);
        try {
            axios.post('https://api.spmsale.com/api/product', { id: sub_id, page: value, limit: Limit })
                .then(function (response) {
                    if (response.data.status) {
                        setProduct(response.data.data);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }

    }

    const { sub_id } = useParams();
    useEffect(() => {
        setProductId(sub_id)
        getProduct(sub_id);
    }, [sub_id]);
    return (
        <section className="web_content">
            <div className='content_section_scroll'>
                <div className="container-fluid">
                    {
                        (ProductId <= 2) ?
                            <div className="row d-flex align-items-top">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                    <div className="common_content">
                                        {
                                            (ProductId == 1) ?
                                                <h1>Electronics Components</h1>
                                                :
                                                ''
                                        }
                                        {
                                            (ProductId == 2) ?
                                                <h1>Aircraft Part</h1>
                                                :
                                                ''
                                        }
                                        <div className="product_content">
                                            <div className="product_table">
                                                <table className="table table-bordered" id='productTable'>
                                                    <thead>
                                                        <tr>
                                                            <td className='width10'>PART</td>
                                                            <td className='width20'>MANUFACTURER NAME &amp; PART</td>
                                                            <td className='width42'>DESCRIPTION</td>
                                                            <td className='width15'></td></tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            (Product.length > 0) ?
                                                                Product.map((element) => {
                                                                    return <tr key={element.id}>
                                                                        <td><NavLink className="nav-link" to={"/products-details/" + element.part_number}>{element.part_number}</NavLink></td>
                                                                        <td>{element.manufacturer}</td>
                                                                        <td>{element.parts_description}</td>
                                                                        <td><NavLink className="btn_table" to={"/products-details/" + element.part_number}>Request Quote</NavLink></td>
                                                                    </tr>
                                                                })
                                                                :
                                                                <tr>
                                                                    <td className='ct_center' colSpan={4}>No data found</td>
                                                                </tr>

                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                    {
                                        (Product.length > 0 && totalPage > 1) ?
                                            <Pagination count={totalPage} page={page} onChange={handleChange} color="primary" size="large" />
                                            : ''
                                    }

                                </div>
                            </div>
                            :
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <img className="comingSoon" src="/images/coming-soon.jpg" alt="coming-soon" />
                            </div>
                    }


                </div>

            </div>

            {/* <Pagination count={10} /> */}

        </section>

    )
}

export default Product
