
import React, { useState, useEffect } from 'react'
import { useSearchParams } from "react-router-dom";
import $ from 'jquery';

const axios = require('axios');

const ChangePass = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const FormSubmit = (e) => {
        e.preventDefault();
        var validation = true;
        const validPass = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@_])[A-Za-z\d@_]{8,}$/;
        $('#updatePassError').text('');
        $('#cUpdatePassError').text('');
        if (!validPass.test(password)) {
            $('#updatePassError').text("Password is not valid");
            validation = false;
        } else if (password != confirmPassword) {
            $('#cUpdatePassError').text("Confirm password is not match with password");
            validation = false;
        }
        if (validation == false) {
            return false;
        }
        const data = {
            'password': password,
            'token' : searchParams.get("email")
        }
        try {
            axios.post('https://api.spmsale.com/api/update-pass', data)
                .then(function (response) {
                    if (response.data.Success) {
                        alert(response.data.Success);
                        window.$('#ChangePassModel').modal('hide');
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    };
    return (

        <div className="contact_form">
            <div className="row">
                <div className="col-md-12">
                    <div className="cf_tab">
                        <input type="password" id="updatePassword" value={password} onChange={(e) => setPassword(e.target.value)} name="updatePassword" placeholder="Password" className="form-control" />
                        <h5 className="ra_tab_small">Only ( 0-9,A-Z,a-z,@ and _ ) Characters used (minimum 8 characters required).</h5>
                        <div id="updatePassError" className="error" ></div>
                        <input type="password" id="confirm_password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} name="confirm_password" placeholder="Confirm password" className="form-control" />
                        <div id="cUpdatePassError" className="error" ></div>
                    </div>
                    <div id="mailError" className="error" ></div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 text-center">
                    <div className="cf_tab">
                        <button type="submit" className="btn btn_inquiry" onClick={FormSubmit}>Send</button>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ChangePass
