import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate } from "react-router-dom";
import $ from 'jquery';
const axios = require('axios');



const Header = () => {
    const [loginData, setLoginData] = useState(localStorage.loginData);
    const [category, setcategory] = useState([]);
    const [searchInput, setSearchInput] = useState('');

    const navigate = useNavigate();
    const logout = () => {
        localStorage.removeItem('loginData');
        setLoginData('');
        navigate("/login");
    }
    const handleChange = (e) => {
        setSearchInput(e.target.value);
    }
    const getCategory = () => {
        try {
            axios.post('https://api.spmsale.com/api/category')
                .then(function (response) {
                    if (response.data.status) {
                        setcategory(response.data.data);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    }
    const searchClick = () => {
        if ($('.search-toggle').hasClass('closed')) {
            $('.search-toggle').removeClass('closed').addClass('opened');
            $('.search-toggle, .search-container').addClass('opened');
            $('#search-terms').focus();
        } else {
            $('.search-toggle').removeClass('opened').addClass('closed');
            $('.search-toggle, .search-container').removeClass('opened');
        }
    }
    const searchSubmit = () => {
        if (searchInput) {
            navigate("/products-search/" + searchInput);
        }
        $('.search-toggle').removeClass('opened').addClass('closed');
        $('.search-toggle, .search-container').removeClass('opened');
    }
    useEffect(() => {
        getCategory();
        setLoginData(localStorage.loginData);
    }, []);
    return (

        <header className="web_header" id="home">
            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid">
                    <NavLink className="nav-link navbar-brand" to="/">
                        <img className="img_light" src="/images/logo.png" alt="SPM Logo" />
                    </NavLink>
                    <div className="top_btn_mobile">
                        <div className="info_button">
                            <a href="#"><i className="fa fa-search"></i></a>
                            <a href="#"><i className="fa fa-shopping-cart"></i></a>
                            <a href="#"><i className="fa fa-user"></i></a>
                        </div>
                        <button className="navbar-toggler collapsed" type="button" data-toggle="collapse"
                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span className="icon-bar top-bar"></span>
                            <span className="icon-bar middle-bar"></span>
                            <span className="icon-bar bottom-bar"></span>
                        </button>
                    </div>
                    <div className="navbar-collapse collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            {/* <li className="nav-item"><a className="nav-link active" href="index.html">Home</a></li> */}
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/">
                                    Home
                                    <span className="sr-only">(current)</span>
                                </NavLink>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link" href="#" id="navbarDropdown">
                                    Inventory
                                </a>
                                <ul className="dropdown-menu" id="navbarDropdown_sub">
                                    {
                                        category.map((element) => {
                                            if (element.id == 5) {
                                                return <li key={element.id}>
                                                    <a className="nav-link" href="#" id="softWareSub">
                                                    {element.name}
                                                    </a>
                                                    <ul className="dropdown-menu" id="softWareSub_menu">
                                                        {
                                                            category.map((element1) => {

                                                                return <li key={element1.id}><NavLink className="nav-link" to={"/product/" + element1.id}>{element1.name} test</NavLink></li>;


                                                            })
                                                        }

                                                    </ul>
                                                </li>;
                                            } else {
                                                return <li key={element.id}><NavLink className="nav-link" to={"/product/" + element.id}>{element.name}</NavLink></li>;
                                            }

                                        })
                                    }

                                </ul>
                            </li>
                            <li className="nav-item"><NavLink className="nav-link" to="/about-us">About Us</NavLink></li>
                            {
                                loginData ?  // if has image
                                    <li className="nav-item"><NavLink className="nav-link" to="/contact-us">Contact us</NavLink></li>
                                    : ''
                            }

                        </ul>
                    </div>
                    <div className="top_btn_desktop">
                        <div className="info_search">
                            <div className="search-toggle closed">
                                <button className="search-icon icon-search" onClick={searchClick}><i className="fa fa-fw fa-search"></i></button>
                                <button className="search-icon icon-close" onClick={searchClick}><i className="fa fa-fw  fa-close"></i></button>
                            </div>
                            <div className="search-container">
                                <input type="text" onChange={handleChange} placeholder="Search terms..." />
                                <button type="submit" onClick={searchSubmit} className="search-button"><i className="fa fa-fw fa-search"></i></button>
                            </div>
                        </div>
                        <div className="info_button">
                            {/* <NavLink className="info_anchor nav-link" to="/products-search/G6J-2FL-Y-TR-DC5"><i className="fa fa-search"></i></NavLink> */}
                            <a className="info_anchor" href="#"><i className="fa fa-shopping-cart"></i></a>
                            <a className="info_anchor" href="#" id="userProfile"><i className="fa fa-user"></i></a>
                            {
                                loginData ?  // if has image
                                    <ul className="dropdown-menu" id="userProfile_sub">
                                        <li onClick={logout}><a className="nav-link" href="#">Logout</a></li>
                                    </ul>          // return My image tag 
                                    :
                                    <ul className="dropdown-menu" id="userProfile_sub">
                                        <li><NavLink className="nav-link" to="/login">Login</NavLink></li>
                                        <li><NavLink className="nav-link" to="/registration">Registration</NavLink></li>
                                    </ul>        // otherwise return other element  

                            }

                        </div>
                        <button className="navbar-toggler collapsed" type="button" data-toggle="collapse"
                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span className="icon-bar top-bar"></span>
                            <span className="icon-bar middle-bar"></span>
                            <span className="icon-bar bottom-bar"></span>
                        </button>

                    </div>
                </div>
            </nav>
        </header>

    )
}

export default Header
