import React, { useEffect, useState } from 'react'
import { NavLink, useParams } from "react-router-dom";
import ContactForm from './ContactForm';
const axios = require('axios');

const ProductsDetails = () => {
    const [loginData, setLoginData] = useState(localStorage.loginData);
    const [ProductDetail, setProductDetail] = useState([]);
    const { product_id } = useParams();

    const getProductDetail = (product_id) => {
        try {
            axios.post('https://api.spmsale.com/api/product-detail', { id: product_id })
                .then(function (response) {
                    if (response.data.status) {
                        setProductDetail(response.data.data);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getProductDetail(product_id);
    }, []);
    return (

        <section className="web_content">
            <div className='content_section_scroll'>

                <div className="container-fluid">
                    <div className="row d-flex align-items-top">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div className="common_content">
                                <h1>Quote Request For : {ProductDetail.part_number}</h1>
                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                                    <span className='col-xl-10 col-lg-10 col-md-10 col-sm-10'>Quote Request For : {ProductDetail.part_number}</span>
                                    <span className='col-xl-2 col-lg-2 col-md-2 col-sm-2'>
                                        {
                                            loginData ?
                                                <button type="button" className="btn btn-primary requestButton" data-toggle="modal" data-target="#exampleModal">Request for Quote</button>
                                                : ''
                                        }
                                    </span>
                                </div>
                                <div className="product_content">
                                    <div className="product_table">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <td>Specification</td>
                                                    <td>Value</td>
                                                    <td>Specification</td>
                                                    <td>Value</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Part Number</td>
                                                    <td>{ProductDetail.part_number}</td>
                                                    <td>Manufacturer</td>
                                                    <td>{ProductDetail.manufacturer}</td>
                                                </tr>
                                                <tr>
                                                    <td>Alternative part number</td>
                                                    <td>{ProductDetail.alternative_part_number}</td>
                                                    <td>NSN Part number</td>
                                                    <td>{ProductDetail.nsn_part_number}</td>
                                                </tr>
                                                <tr>
                                                    <td>Date Code</td>
                                                    <td>{ProductDetail.date_code}</td>
                                                    <td>Total Available</td>
                                                    <td>{ProductDetail.total_available}</td>
                                                </tr>
                                                <tr>
                                                    <td>Quantity</td>
                                                    <td>{ProductDetail.quantity}</td>
                                                    <td>Inventory Type</td>
                                                    <td>{ProductDetail.inventory_type}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total Stock</td>
                                                    <td>{ProductDetail.total_stock}</td>
                                                    <td>Total Excess</td>
                                                    <td>{ProductDetail.total_excess}</td>
                                                </tr>
                                                <tr>
                                                    <td>Parts Description</td>
                                                    <td>{ProductDetail.parts_description}</td>
                                                    <td>Category</td>
                                                    <td>{ProductDetail.category}</td>
                                                </tr>
                                                <tr>
                                                    <td>Condition</td>
                                                    <td>{ProductDetail.part_number}</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Request for Quote</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <ContactForm />
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )
}

export default ProductsDetails
