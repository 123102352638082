import React from 'react'

const CBSMT = () => {
    return (
        <section className="web_content">
            <div className="common_bg">
                <div className="content_section">
                    <div className="container-fluid">
                        <div className="row d-flex align-items-center">
                            <div className="col-xl-12 col-lg-12 col-md-2 col-sm-12">
                                <div className="common_content">
                                    <h1>Cloud based sales management tools</h1>
                                    <p>SPM Sales and Solution is a distributor of Electronics Components, Avionic parts and raw materials for avionics parts. At SPM Sales and Solution, we are committed to Customer Satisfaction. It is our commitment to excellence, along with long-term supplier relationships, that allow us to accomplish this. Our single point of contact provides ease of doing business, while affording you the flexibility for unsurpassed service. SPM Solution represents more than 200 Manufacturers, we are confident that a solution can be provided to service your electronic needs. Contact SPM Sales and Solution for their excellent service.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CBSMT
